import '../node_modules/normalize.css/normalize.css'

import '../node_modules/nes.css/scss/nes.scss'

import './style.css'

import React from 'react'

import {Component} from 'react'

import {render} from 'react-dom'

class App extends Component {

  render(): React.ReactNode {
    return (<div className={"container"}>
      <section className={"nes-container is-centered with-title"}>
        <h1 className={"title header"}>grimrose.dev</h1>
        <div className={"share"}>
          <a href="https://twitter.com/grimrose">
            <i className={"nes-icon twitter is-large"}/>
          </a>
          <a href="https://github.com/grimrose">
            <i className={"nes-icon github is-large"}/>
          </a>
        </div>
      </section>
    </div>);
  }
}

render(<App/>, document.getElementById('root'));
